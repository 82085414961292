import { makeStyles } from '@mui/styles';

interface ModalStyleProps {
  backgroundColor?: string;
  width?: string;
  padding?: string;
  rounded?: string;
  overflowY?: 'auto' | 'visible';
}

export const useStyles = makeStyles(() => ({
  paper: (props: ModalStyleProps) => ({
    backgroundColor: props.backgroundColor ?? 'var(--white)',
    border: 'none',
    borderRadius: props.rounded ? props.rounded : 0,
    boxShadow: 'none',
    overflowY: props.overflowY || 'auto',
    padding: props.padding ? props.padding : '30px',
    ...(props.width && { maxWidth: props.width, width: '100%' }),
  }),
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#1A1538',
    zIndex: 2,
  },
  title: {
    marginBottom: '24px',
    display: 'flex',
    color: '#1A1538',
    fontSize: '24px',
    textAlign: 'left',
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    flexDirection: 'column',
  },
  dialogTitle: {
    paddingTop: 24,
  },
  blur: {
    // backdropFilter: 'blur(1px)',
    background: 'rgba(26, 21, 56, 0.6)',
  },
}));
