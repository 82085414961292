import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  topWrapper: {
    width: '95.5vw',
    height: '16vw',
    maxWidth: '95%',
    maxHeight: '86px',
    border: '1.5px solid #2D11AD',
    borderRadius: '9px',
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  getStartedText: {
    color: '#2D11AD',
    fontSize: 'clamp(18px, 5vw, 27px)',
    textAlign: 'center',
    fontWeight: 400,
    lineHeight: '20px',
  },
  sweepStakeTitle: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'center',
    paragraph: '24px',
    color: '#2D11AD',
    margin: '8px 8px',
  },
  sweepStakeDescription: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    paragraph: '20px',
    color: '#475467',
    marginBottom: '16px',
  },
  recordVideoText: {
    color: '#474747',
    fontSize: 'clamp(14px, 3.9vw, 21px)',
    textAlign: 'center',
    lineHeight: '20px',
    [`@media (min-width: 430px)`]: {
      marginTop: '6px',
    },
  },
  signUpFormWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
  },
  nameText: {
    color: '#344054',
    fontWeight: '600',
    width: '81.9vw',
    maxWidth: '442px',
    fontSize: 'clamp(18px, 5vw, 27px)',
    marginBottom: '7px',
  },
  input: {
    width: '81.9vw',
    height: '12.2vw',
    maxWidth: '442px',
    maxHeight: '52px',
    border: '1px solid',
    borderRadius: '8px',
    borderColor: '#D0D5DD',
    marginBottom: '10px',
    fontSize: 'clamp(16px, 4.4vw, 24px)',
    paddingLeft: '12px',
    '& .MuiInput-input:-webkit-autofill, .MuiInput-input:-webkit-autofill:focus, .MuiInput-input:-webkit-autofill:hover':
      {
        WebkitTextFillColor: '#2D11AD',
        fontSize: 'clamp(16px, 4.4vw, 24px)',
      },
    '& input': {
      fontSize: 'clamp(16px, 4.4vw, 24px)',
      color: '#2D11AD',
      '&::placeholder': {
        color: '#A9B3C8',
      },
    },
    '&.Mui-error': {
      borderColor: '#ED2E38',
    },
  },
  errorText: {
    fontSize: 'clamp(11px, 3.05vw, 16.5px )',
    color: '#ED2E38',
    width: '81.9vw',
    maxWidth: '442px',
    marginTop: '-8px',
    marginBottom: '10px',
    letterSpacing: '0.11px',
  },
  btnsWrapper: {
    width: '100%',
    height: '100%',
  },
  btn: {
    width: '81.9vw',
    height: '12.2vw',
    maxWidth: '442px',
    maxHeight: '52px',
    fontSize: 'clamp(16px, 4.4vw, 24px )',
    fontWeight: 600,
    borderRadius: '8px',
    '&:active': {
      transform: 'scale(0.99)',
    },
  },
  btnUpload: {
    background: '#2D11AD',
    color: '#fff',
    marginTop: '24px',
    marginBottom: '12px',
    '&:active': {
      transform: 'scale(0.99)',
    },
    '&:hover': {
      background: '#2D11AD',
    },
    '&:disabled': {
      background: '#DFDFDF',
    },
  },
  btnRecord: {
    background: '#fff',
    color: '#2D11AD',
    border: '1px solid #2D11AD',
    '&:active': {
      transform: 'scale(0.99)',
    },
    '&:hover': {
      background: '#fff',
    },
    '&:disabled': {
      color: '#DFDFDF',
      border: '1px solid #D0D5DD',
    },
  },
  termsWrapper: {
    marginTop: '26px',
    maxWidth: '90%',
  },
  capthaWrapper: {
    overflow: 'hidden',
    position: 'relative',
    height: '100px',
    width: '100%',
    '& .grecaptcha-badge': {
      position: 'absolute !important',
      bottom: '15px !important',
    },
  },
}));
