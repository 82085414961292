import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

type UseBlockNavigationOptions = {
  shouldBlock: boolean;
};

export const useBlockNavigation = ({ shouldBlock }: UseBlockNavigationOptions) => {
  const router = useRouter();
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
  const confirmNavigation = () => {
    if (pendingNavigation) {
      router.push(pendingNavigation); // Proceed with the pending navigation
      setPendingNavigation(null); // Clear pending navigation
    }
  };

  const cancelNavigation = () => {
    setPendingNavigation(null); // Clear pending navigation
  };

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (shouldBlock) {
        // Prevent navigation and store the pending URL
        setPendingNavigation(url);
        setTimeout(() => {
          return false;
        }, 3000);
        // Return false to stop the route change
      }
    };

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldBlock) {
        event.preventDefault();
        event.stopPropagation(); // Prevent the native prompt from showing
        event.returnValue = ''; // For compatibility with different browsers
      }
    };

    // Intercept route changes
    router.events.on('routeChangeStart', handleRouteChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldBlock, router]);

  return { confirmNavigation, cancelNavigation, pendingNavigation };
};
