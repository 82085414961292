import { useAppDispatch } from '../../../store/store';
import { addUploadingIteration, setProgress } from '../../../store/slices/upload';

export const useOnUploadProgress = () => {
  const dispatch = useAppDispatch();

  const onUploadProgress = (event: ProgressEvent) => {
    let percentCompleted = Math.round((event.loaded * 100) / event.total);
    dispatch(setProgress(percentCompleted));
    dispatch(addUploadingIteration({ timeStamp: event.timeStamp, loaded: event.loaded, total: event.total }));
  };

  return onUploadProgress;
};

interface ResumableProgressEvent {
  totalBytes: number;
  uploadedBytes: number;
  chunkIndex: number;
  chunkLength: number;
}

export const useOnResumableUploadProgress = () => {
  const dispatch = useAppDispatch();

  const onUploadProgress = (event: ResumableProgressEvent) => {
    console.log('event', event);
    let percentCompleted = Math.round((event.uploadedBytes * 100) / event.totalBytes);
    dispatch(setProgress(percentCompleted));
    dispatch(
      addUploadingIteration({
        timeStamp: new Date().getUTCMilliseconds(),
        loaded: event.uploadedBytes,
        total: event.totalBytes,
      }),
    );
  };

  return onUploadProgress;
};
//uploadedBytes
