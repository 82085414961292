import { FC } from 'react';
import { Grid, Button, Box } from '@mui/material';

import { FSModal } from '../FSModal/FSModal';
import { ModalTitle, ModalContent } from './RouteLeavingModal.style';
import { CommonStrings } from '../../localization/en';
interface RouteLeavingModalProps {
  open: boolean;
  titleText: string;
  contentText: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const RouteLeavingModal: FC<RouteLeavingModalProps> = ({
  open,
  titleText,
  contentText,
  onCancel,
  onConfirm,
}) => {
  return (
    <>
      <FSModal modalIsOpen={open} onClose={onCancel}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={8}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12}>
                <ModalTitle>{titleText}</ModalTitle>
              </Grid>
              <Grid item xs={12}>
                <ModalContent>{contentText}</ModalContent>
              </Grid>
              <Box mt={3} display="flex">
                <Box mr={3}>
                  <Grid item xs={2}>
                    <Button color="primary" onClick={onCancel}>
                      {CommonStrings.Cancel}
                    </Button>
                  </Grid>
                </Box>
                <Grid item xs={2}>
                  <Button variant="contained" color="primary" onClick={onConfirm}>
                    {CommonStrings.Leave}
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </FSModal>
    </>
  );
};
