import { Box, Divider, Typography } from '@mui/material';
import { useTypedSelector } from '../../../store/store';
import { useIncentiveBlockStyles } from './IncentiveBlock.styles';

enum IncentiveBlockStrings {
  WeAppreciateYou = 'We appreciate you!',
  LearnMore = 'Learn more ',
  DefaultIncentiveText = 'about the $1,000 Monthly Giveaway',
  FooterNote = 'Do you want to capture videos for your business? ',
  FooterAction = 'Learn More \u25B8',
}

export const IncentiveBlock = () => {
  const classes = useIncentiveBlockStyles();
  const { account } = useTypedSelector((state) => state.account);
  return (
    <>
      <Box className={classes.titleContainer}>
        {/* <ReceivedIcon color="#058901" /> */}
        <Typography className={classes.title}>{IncentiveBlockStrings.WeAppreciateYou}</Typography>
        <Typography className={classes.accountName}>- {account.name}</Typography>
      </Box>

      <Box className={classes.contentContainer}>
        {/* <Typography className={styles.mainMessageTitle}>
            {SharePageStrings.YouAreAllSet.replace(
              '{name}',
              me.firstName ? me.firstName : shareStory.userName.split(' ')[0],
            )}
          </Typography>
          <Typography className={styles.mainMessageText}>
            {SharePageStrings.ThankYou.replace('{name}', account.name)}
          </Typography> */}

        <Divider className={classes.divider} />

        <Typography className={classes.bottomText}>
          <span style={{ color: '#2D11AD', fontWeight: 'bold' }}>{IncentiveBlockStrings.LearnMore}</span>
          {IncentiveBlockStrings.DefaultIncentiveText}
        </Typography>

        {/*  Share stuff
          
          <Typography className={styles.bottomText}>{SharePageStrings.WouldYouShareVideo}</Typography>

          <Button
            variant="outlined"
            href={`${shareStory.videoPageUrl}&share=true`}
            className={styles.shareButton}
            fullWidth
          >
            {SharePageStrings.ShareVideo}
          </Button> */}

        <Divider className={classes.divider} />
      </Box>
      <Box position={'absolute'} bottom={'15%'} left="0">
        <Typography className={classes.bottomText}>
          {IncentiveBlockStrings.FooterNote}
          <span style={{ color: '#2D11AD', fontWeight: 'bold' }}>{IncentiveBlockStrings.FooterAction}</span>
        </Typography>
      </Box>
    </>
  );
};
