import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { openDB } from 'idb';
import {
  changeStep,
  createResumableUploadUrl,
  CreateStoryRequest,
  createUploadUrl,
  resetUploadingIterations,
  setUploadError,
  UploadStoryDetails,
  uploadVideo,
  // uploadVideoAsFile,
} from '../../../store/slices/upload';
import Upload from 'gcs-browser-upload';
import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';
import { EventNames } from '../../../common/constants/constants';
import { getVideoPosterWithCloudinary, prefetchUrl } from '../../../pages/VideoToolPage/utils';
import {
  getSyncUpdatedVideoUrl,
  getVideoAspectRatio,
  getVideoDuration,
  isVideoARCloseToTargetAR,
} from '../../utilities';
import { useOnResumableUploadProgress, useOnUploadProgress } from './useUploadProgress';
import { useAttributesFilterUrl, useLogoFilterEnabled } from '../useAttributesFilterUrl';
import { usePostStory } from '../usePostStory';
import { useTrackEvent } from '../../../common/hooks/useTrackEvent';
import { trackEvent as globalTrackEvent } from '../../../../utils/analytics/analytics';
import { UploadProcessStep } from '../../../store/storeModels';
import { DEFAULT_STORY_DETAILS } from '../../../api/constants';

export interface VideoPos {
  x: number;
  y: number;
  width: number;
}

export interface HandleSubmitFileProps {
  file: File | null;
  details: UploadStoryDetails;
  isPublic: boolean;
  videoPos: VideoPos;
  shouldPostStory?: boolean;
  webappRecorded: boolean;
}

export const useHandleSubmitUploadedFile = (
  setStoryId: Dispatch<SetStateAction<string>>,
  isAthleticSolutionsClient: boolean,
  returnUrl: string,
) => {
  const dispatch = useAppDispatch();

  const attributesFilterUrl = useAttributesFilterUrl();
  const logoEnabled = useLogoFilterEnabled();
  const { id } = useTypedSelector((state) => state.me);
  const { mainCategory } = useTypedSelector((state) => state.stories);
  const { account } = useTypedSelector((state) => state.account);
  const logoSrc = account.logo;
  const { id: categoryId } = mainCategory || {};

  const onUploadProgress = useOnUploadProgress();
  const onResumableUploadProgress = useOnResumableUploadProgress();
  const postStory = usePostStory(setStoryId);
  const { trackEvent } = useTrackEvent();

  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [resumeUrl, setResumeUrl] = useState<string>('');
  const [resumableUpload, setResumableUpload] = useState<Upload | null>();

  const restartUpload = () => async () => {
    if (!resumableUpload || !resumeFile) return;
    resumableUpload;
    const duration = await getVideoDuration(resumeFile);
    const videoAr = await getVideoAspectRatio(resumeFile);
    const shouldFill = !isVideoARCloseToTargetAR(videoAr);

    try {
      // const response = await initialPost({ options: res });
      // console.log('response', response);
      try {
        await resumableUpload.start();
      } catch (err) {
        if (JSON.stringify(err).includes('200 (OK)')) {
          globalTrackEvent({
            action: EventNames.Upload_Failed,
            accountId: account.id,
            errorMessage: JSON.stringify(err),
            userId: id,
          });
          dispatch(setUploadError(true));
        }
      }

      trackEvent(EventNames.Upload_Completed);
      const downloadUrl = resumeUrl.split('?')[0];
      const url = getSyncUpdatedVideoUrl(
        resumeUrl.split('?')[0],
        logoSrc && logoEnabled ? attributesFilterUrl : undefined,
        0,
        trackEvent,
        shouldFill,
      );

      const newThumbnailUrl = getVideoPosterWithCloudinary(url || '', trackEvent) || '';

      const urlWithoutLogo = getSyncUpdatedVideoUrl(downloadUrl, undefined, undefined, trackEvent);
      const altThumbnailUrl = getVideoPosterWithCloudinary(urlWithoutLogo || '', trackEvent);

      const story: CreateStoryRequest = {
        categoryId: categoryId || '',
        url: url,
        thumbnailUrl: newThumbnailUrl,
        altThumbnailUrl,
        isPublic: true,
        duration,
        details: {
          ...DEFAULT_STORY_DETAILS(),
          ctaBtnLinkUrl: returnUrl,
        },
        webappRecorded: false,
      };
      return story;
    } catch (error) {
      globalTrackEvent({
        action: EventNames.Upload_Failed,
        accountId: account.id,
        errorMessage: JSON.stringify(error),
        userId: id,
      });
      dispatch(setUploadError(true));
      return undefined;
    }
  };

  const saveUploadToDB = async (
    resumeFile: File,
    resumeUrl: string,
    duration: number,
    returnUrl: string,
    campaignId: string,
    token: string,
    origin: string,
  ) => {
    console.log('saving to db');
    const db = await openDB('VideoUploadQueue', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('uploads')) {
          db.createObjectStore('uploads', { keyPath: 'id' });
        }
      },
    });

    const id = crypto.randomUUID();
    await db.put('uploads', { id, resumeFile, resumeUrl, duration, returnUrl, campaignId, token, origin });
    console.log('saving to db', id);
    return id;
  };

  const removeFromVideoUploadQueue = async (id: string) => {
    console.log('id to delete', id);
    try {
      // Open the IndexedDB database
      const db = await openDB('VideoUploadQueue', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('uploads')) {
            db.createObjectStore('uploads', { keyPath: 'id' });
          }
        },
      });

      // Perform the deletion
      const transaction = db.transaction('uploads', 'readwrite');
      const store = transaction.objectStore('uploads');
      await store.delete(id);

      // Ensure the transaction completes
      await transaction.done;

      console.log(`Element with id ${id} removed from VideoUploadQueue.`);
    } catch (error) {
      console.error('Failed to remove element from VideoUploadQueue:', error);
    }
  };

  const handleUploadedFile = async ({
    file,
    details,
    isPublic,
    videoPos,
    shouldPostStory,
    webappRecorded,
  }: HandleSubmitFileProps): Promise<CreateStoryRequest | undefined> => {
    if (!file) return;

    dispatch(setUploadError(false));
    const duration = await getVideoDuration(file);
    const videoAr = await getVideoAspectRatio(file);
    const shouldFill = !isVideoARCloseToTargetAR(videoAr);

    try {
      const res = await createResumableUploadUrl(file.type);
      if (!res) return;
      dispatch(changeStep(UploadProcessStep.VideoUploading));
      setResumeFile(file);
      setResumeUrl(res.resumableUploadUrl);

      const id = await saveUploadToDB(
        file,
        res.resumableUploadUrl,
        duration,
        returnUrl,
        localStorage.getItem('campaignId') || '',
        localStorage.getItem('authToken') || '',
        window.location.origin,
      );

      // const response = await initialPost({ options: res });
      // console.log('response', response);
      // const id = crypto.randomUUID();
      const upload = new Upload({
        id: id,
        url: res.resumableUploadUrl,
        file: file,
        chunkSize: 786432,
        contentType: res.contentType || file.type || 'application/octet-stream',
        onChunkUpload: onResumableUploadProgress,
      });
      setResumableUpload(upload);
      try {
        await upload.start();
      } catch (err) {
        const isSuccess = await prefetchUrl(res.resumableUploadUrl.split('?')[0]);
        console.log('error caught within here', err);
        console.log('isSuccess', isSuccess);
        if (!isSuccess) {
          globalTrackEvent({
            action: EventNames.Upload_Failed,
            accountId: account.id,
            errorMessage: JSON.stringify(err),
            userId: id,
          });
          dispatch(setUploadError(true));
          return;
        }
      }
      removeFromVideoUploadQueue(id);

      trackEvent(EventNames.Upload_Completed);
      const downloadUrl = res.resumableUploadUrl.split('?')[0];
      const url = getSyncUpdatedVideoUrl(
        res.resumableUploadUrl.split('?')[0],
        logoSrc && logoEnabled ? attributesFilterUrl : undefined,
        videoPos.width,
        trackEvent,
        shouldFill,
      );

      //TODO: Below to worker
      const newThumbnailUrl = getVideoPosterWithCloudinary(url || '', trackEvent) || '';

      const urlWithoutLogo = getSyncUpdatedVideoUrl(downloadUrl, undefined, undefined, trackEvent);
      const altThumbnailUrl = getVideoPosterWithCloudinary(urlWithoutLogo || '', trackEvent);

      const story: CreateStoryRequest = {
        categoryId: categoryId || '',
        url: url,
        thumbnailUrl: newThumbnailUrl,
        altThumbnailUrl,
        isPublic: isPublic,
        duration,
        details: {
          ...details,
          ctaBtnLinkUrl: isAthleticSolutionsClient ? returnUrl : details.ctaBtnLinkUrl || null,
        },
        webappRecorded,
      };

      if (!shouldPostStory) return story;

      await postStory(story);
      dispatch(resetUploadingIterations());
      return story;
    } catch (error) {
      console.log('error caught here', error);
      globalTrackEvent({
        action: EventNames.Upload_Failed,
        accountId: account.id,
        errorMessage: JSON.stringify(error),
        userId: id,
      });
      dispatch(setUploadError(true));
      return undefined;
    }
  };

  const handleSubmitVideo = async (
    blob: string | undefined,
    details: UploadStoryDetails,
    isPublic: boolean,
    supportedMimeType: string,
    smsNotificationRef?: MutableRefObject<boolean | undefined>,
    webappRecorded?: boolean,
  ) => {
    createUploadUrl(supportedMimeType).then(async (res) => {
      if (blob && res) {
        dispatch(changeStep(UploadProcessStep.VideoUploading));
        try {
          await uploadVideo({ options: res, data: blob, onUploadProgress });
          const duration = await getVideoDuration(blob);
          trackEvent(EventNames.Upload_Completed);
          const url = getSyncUpdatedVideoUrl(
            res.downloadUrl,
            logoSrc && logoEnabled ? attributesFilterUrl : undefined,
            undefined,
            trackEvent,
          );

          const newThumbnailUrl = getVideoPosterWithCloudinary(url || '', trackEvent);

          const urlWithoutLogo = getSyncUpdatedVideoUrl(res.downloadUrl, undefined, undefined, trackEvent);
          const altThumbnailUrl = getVideoPosterWithCloudinary(urlWithoutLogo || '', trackEvent);

          postStory({
            categoryId: categoryId || '',
            url,
            thumbnailUrl: newThumbnailUrl,
            altThumbnailUrl,
            isPublic: isPublic,
            duration,
            sendSMSNotification: smsNotificationRef?.current || false,
            details: {
              ...details,
              ctaBtnLinkUrl: isAthleticSolutionsClient ? returnUrl : details.ctaBtnLinkUrl || null,
            },
            webappRecorded,
          });
        } catch (err) {
          console.log(err);
          globalTrackEvent({
            action: EventNames.Upload_Failed,
            accountId: account.id,
            errorMessage: JSON.stringify(err),
            userId: id,
          });
          dispatch(setUploadError(true));
        }
      }
    });
  };

  return { handleUploadedFile, handleSubmitVideo, postStory, restartUpload, saveUploadToDB };
};
